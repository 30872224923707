<template>
    <section class="svg-component">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="svg-wrap" style="display: block">
            <g id="lineWrap"></g>
        </svg>
    </section>
</template>

<script>
// const ns = "http://www.w3school.com.cn/edition/";
export default {
    props: {},
    data() {
        return {
            lineArra: [],
        };
    },
    methods: {
        removeLine(line) {
            const svgDom = document.getElementById("lineWrap");

            svgDom.removeChild(line);
        },
    },
    mounted() {
        // const svgDom = document.getElementById("svgDom");
        // const svgDom = document.getElementsByTagNameNS(ns, "svg");
        // svgDom.setAttributeNS(ns, "viewBox", "0 0 10000 20000");
    },
};
</script>

<style lang="less" scoped>
.svg-component {
    position: absolute;
    top: 56px;
    // left: 0;
    width: 53.5px * 6;
    bottom: 0;
    right: 0;
    // background-color: yellow;
}
.svg-wrap {
    position: absolute;
    width: 100%;
    left: 0;
    // width: 48px * 6;
    height: 100%;
}
</style>