<template>
    <section class="dr-tool-wrap" id="toolId">
        <ul class="dr-tool" :class="{'bg-green': bgGreen}">
            <!-- <li @click="close" style="border-right: 1px solid #fff"> -->
            <li @click="close">
                <img :src="Close" class="dr-tool-img" />
                <p>关闭</p>
            </li>
            <li @click="revoke">
                <img :src="Left" class="dr-tool-img" />
                <p>撤销</p>
            </li>
            <li @click="recovery">
                <img :src="Right" class="dr-tool-img" />
                <p>恢复</p>
            </li>
            <li @click="clear">
                <img :src="Remove" class="dr-tool-img" />
                <p>清除</p>
            </li>
            <li @click="share">
                <img :src="Share" class="dr-tool-img" />
                <p>分享</p>
            </li>
            <li @click="share">
                <img :src="Save" class="dr-tool-img" />
                <p>保存</p>
            </li>
            <li @click="setting">
                <img :src="ToolSetting" class="dr-tool-img" />
                <p>设置</p>
            </li>
        </ul>
        <van-popup
            v-model="settingShow"
            position="right"
            :style="{ height: '100%', width: '90%', textAlign: 'left' }"
        >
            <van-button
                icon="arrow-left"
                style="margin: 0 0.6rem"
                size="mini"
                @click="hideSetting"
                >返回</van-button
            >
            <van-radio-group
                v-model="pageSize"
                direction="horizontal"
                style="font-size: 0.36rem; margin: 0.6rem 0.6rem"
            >
                <van-radio :name="30">30期</van-radio>
                <van-radio :name="60">60期</van-radio>
                <van-radio :name="90">90期</van-radio>
            </van-radio-group>
            <div :style="{ 'text-align': 'right', padding: '0 10px' }">
                <van-button type="primary" @click="onSubmit">确定</van-button>
            </div>
            <van-button
                style="margin: 0 0.6rem"
                @click="toSevenOrFive"
                >{{ isOrderFive()? "去七星彩":"去排列五" }}</van-button
            >
        </van-popup>
        <ul
            class="dr-tool"
            style="position: relative; z-index: -1; position: initial"
        >
            <!-- <li @click="close" style="border-right: 1px solid #fff"> -->
            <li @click="close">
                <img :src="Close" class="dr-tool-img" />
                <p>关闭</p>
            </li>
        </ul>
    </section>
</template>

<script>
import Close from "./images/tool-close.png";
import Left from "./images/tool-left.png";
import Right from "./images/tool-right.png";
import Remove from "./images/tool-remove.png";
import Share from "./images/tool-share.png";
import Save from "./images/tool-save.png";
import ToolSetting from "./images/tool-setting.png";

export default {
    props: {
        bgGreen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            Close,
            Left,
            Right,
            Remove,
            Share,
            Save,
            ToolSetting,
            settingShow: false,
            pageSize: sessionStorage.getItem("pageSize") ? Number(sessionStorage.getItem("pageSize")) : 30,
        };
    },
    watch: {
        // pageSize(newVal) {
        //     this.$emit("updatePageSize", newVal);
        // },
    },
    methods: {
        onSubmit() {
            sessionStorage.setItem("pageSize", this.pageSize);
            this.$emit("updatePageSize", { pageSize: this.pageSize });
        },
        close() {
            this.$emit("close");
        },
        revoke() {
            this.$emit("revoke");
        },
        recovery() {
            this.$emit("recovery");
        },
        clear() {
            this.$emit("clear");
        },
        share() {
            this.$emit("share");
        },
        setting() {
            // this.$emit("setting");
            this.settingShow = true;
        },
        hideSetting() {
            this.settingShow = false;
        },
        isOrderFive() {
            return location.hash === "#/drawingRules";
        },
        toSevenOrFive() {
            if (location.hash === "#/drawingRules") {
                location.hash = '#/drawingRulesSeven'
            } else {
                location.hash = '#/drawingRules'
            }
        }
    },
};
</script>

<style lang="less" scoped>
#toolId .bg-green{
    background-color: #90c282;
}
.dr-tool-wrap {
    // height: 54px;
}
.dr-tool {
    z-index: 111;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // background-color: #59a0dd;
    background-color: #816bda;
    width: 100%;
    // position: fixed;
    // left: 0;
    // right: 0;
    // top: 0;
    li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
        // border: 1px solid #fff;
    }
    p {
        text-align: center;
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 12px;
        margin-bottom: 6px;
        font-size: 6px;
        color: #fff;
    }
}
.dr-tool-img {
    width: 20px;
    height: 20px;
    padding: 6px;
    // border-radius: 50%;
}
</style>