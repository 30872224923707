
import util from "../util"
const {
    hasSelectedBall,
    hideBall,
    showBall,
    createline,
    setLineX1Y1,
} = util;
import Step from "../Step";

export default {
    methods: {
        createline: function ({ x, y, index }) {
            this.touchstartIndex = index;
            this.startXY = [x, y];
            this.line = createline({
                x1: x,
                y1: y,
                x2: x,
                y2: y,
                stroke: this.lineColor,
                strokeWidth: this.lineWidth,
            });

        },
        moveLine({ x2, y2 }) {
            setLineX1Y1({
                line: this.line,
                x2: x2,
                y2: y2,
            });
        },
        moveLineEnd({ centerX, centerY, index }) {
            Step.add({ line: this.line });
            const target = this.divDomArr[index];

            if (this.touchstartIndex === index) {
                if (hasSelectedBall(target)) {
                    hideBall(target);
                } else {
                    Step.add({ ball: index, backgroundColor: this.lineColor, target });
                    
                    console.log(this.lineColor);

                    showBall(target, {
                        backgroundColor: this.lineColor,
                    });
                }
            }
        }
    }
}