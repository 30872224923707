import { isPlainObject } from "lodash";

let INDEX_CACHE = [];
let htmlFontSize = 0;
const obj = {
  parseRem(pxValue) {
    if (htmlFontSize === 0) {
      htmlFontSize = document.getElementsByTagName("html")[0].style.fontSize;
    }
    return pxValue / Number(htmlFontSize.replace(/px/, ""));
  },
  getRem(pxValue) {
    // let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
    //  * (htmlWidth / 750)
    // return (pxValue / 39) + "rem";
    if (htmlFontSize === 0) {
      htmlFontSize = document.getElementsByTagName("html")[0].style.fontSize;
    }
    return pxValue / Number(htmlFontSize.replace(/px/, "")) + "rem";
  },
  /**
   * @description 获取所有的格子的中心点
   */
  getCenterXY(rows, columns, w, h) {
    // 求出每一个格子的宽度
    const itemW = w / columns;
    const itemR = h / rows;
    console.log(`每一个格子的宽度：${itemW}`, `每一个格子的高度：${itemR}`);

    let centerXY = [];
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        // 每一个格子的
        // let rect = [i, j];
        // let x = (((j + 1) * itemW) / 2) + (itemW * j);
        let x = itemW / 2 + itemW * j;
        let y = itemR / 2 + itemR * i;
        // centerXY.push([x, y + offsetTop]);
        centerXY.push([x, y]);
      }
    }
    return centerXY;
  },
  downLoadImg(imageElement, name = "排列五") {
    var image = imageElement;
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      var context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      var url = canvas.toDataURL("image/png", 0.7); //得到图片的base64编码数据
      var a = document.createElement("a"); // 生成一个a元素
      var event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name ? name + ".png" : "photo.png"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
  },
  /**
   * @description 获取每个格子的中心点
   */
  getItemWH(rows, columns, w, h) {
    const itemW = w / columns;
    const itemH = h / rows;
    return {
      itemW,
      itemH,
    };
  },
  /**
   * @description 锁住页面不让其滚动
   * @param {*} flag 是否锁住
   */
  pageLockScroll(flag) {
    console.log(flag);

    if (flag) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "inherit";
      document.body.style.height = "inherit";
    }
  },
  // 获取元素中心点
  // getBallXY(el) {
  //     const obj = el.getBoundingClientRect();
  //     const { x, y, left, top } = obj;
  //     const rx = el.clientWidth;
  //     const ry = el.clientHeight;
  //     // return [x + rx, y + ry];
  //     return {
  //         x: left + rx,
  //         y: top + ry
  //     }
  // },
  /**
   * @description 根据页面拖拽中的当前点的坐标，获取当前点的在哪个div上的index
   * @param {*} arr 数据的数组
   * @param {*} x 拖拽当前的x坐标
   * @param {*} y 拖拽当前的y坐标
   * @param {*} w 每个格子的宽度
   * @param {*} h 每个格子的高度
   * @returns
   */
  getIndexBy(arr, x, y, w, h) {
    let wr = w / 2;
    let hr = h / 2;
    let index = 0;
    const xyArr = arr.filter((item, i) => {
      if (Math.abs(item[0] - x) <= wr + 0 && Math.abs(item[1] - y) <= hr + 0) {
        index = i;
        return true;
      }
      return false;
    });
    if (xyArr.length > 0) {
      INDEX_CACHE = [...xyArr[xyArr.length - 1], index];
      return [...xyArr[xyArr.length - 1], index];
    }
    // return [0, 0, index]
    return INDEX_CACHE;
  },
  /**
   * @description 开始拖拽和结束拖拽的时候把球显示出来
   */
  showBall(
    target,
    option = {
      backgroundColor: "#7e5fff",
      color: "#ffffff",
      borderColor: "#ffffff",
    }
  ) {
    if (target.className.indexOf('future-four') >= 0) {
      target.className = "future-four ball";
    } else {
      target.className = "ball";
    }
    target.style["background-color"] = option.backgroundColor;
    target.style.color = option.color;
    target.style.borderColor = option.borderColor;
    target.style.borderRadius = option.borderRadius;
  },
  /**
   * @description 隐藏球
   */
  hideBall(target) {
    target.className = null;
    target.style["background-color"] = null;
    target.style.color = null;
    target.style.borderColor = null;
    target.style.borderRadius = null;
  },
  hideBallSeven(target) {
    console.log("触发")
    target.className = 'font-div';
    target.style["background-color"] = null;
    target.style.color = null;
    target.style.borderColor = null;
    target.style.borderRadius = null;
  },
  hideFutureBall(target) {
    target.innerText = null;
    target.className = null;
    target.style["background-color"] = null;
    target.style.color = null;
    target.style.borderColor = null;
    target.style.borderRadius = null;
  },
  /**
   * @description 判断球是否已经显示了
   */
  hasSelectedBall(target) {
    return target.className.indexOf("ball") >= 0;
  },
  /**
   * @description 在拖拽过程中把球显示出来，有透明度
   */
  showBallOpacity(
    target,
    option = {
      backgroundColor: "#7e5fff",
    }
  ) {
    if (target.className.indexOf("ball") === -1) {
      target.className = "ballOpacity";
      target.style["background-color"] = option.backgroundColor;
      target.style.color = option.color || null;
      target.style.borderColor = option.borderColor || null;
      target.style.borderRadius =
        option.borderRadius == null ? null : option.borderRadius;
    }
  },
  /**
   * @param {*} balls 移动过程覆盖过的球
   * @param {*} index 当前球的索引
   */
  hideBallOpacity(balls, index) {
    if (isPlainObject(balls)) {
      for (let i in balls) {
        if (i != index) {
          if (balls[i].className.indexOf("ballOpacity") >= 0) {
            balls[i].className = 'font-div';
            balls[i].style["background-color"] = null;
            balls[i].style.color = null;
            balls[i].style.borderColor = null;
            balls[i].style.borderRadius = null;
          }
        }
      }
    }
  },
  /**
   * @description 直线
   */
  createline({ x1, y1, x2, y2, stroke, strokeWidth }) {
    const p0 = "http://www.w3.org/2000/svg";
    const line = document.createElementNS(p0, "line");
    line.setAttribute("x1", x1);
    line.setAttribute("y1", y1);
    line.setAttribute("x2", x2);
    line.setAttribute("y2", y2);
    line.setAttribute("style", `stroke:${stroke};stroke-width:${strokeWidth}`);
    const g = document.getElementById("lineWrap");
    g.appendChild(line);
    return line;
  },
  setLineX1Y1({ line, x2, y2 }) {
    line.setAttribute("x2", x2);
    line.setAttribute("y2", y2);
  },
  /**
   * @description 根据domLine缓存来创建line
   */
  createLineByInstance(line) {
    const g = document.getElementById("lineWrap");
    g.appendChild(line);
  },
  /**
   * @description 删除线
   */
  removeLine(line) {
    const g = document.getElementById("lineWrap");
    g.removeChild(line);
  },
  setLineXYX1Y1({ line, x1, y1, x2, y2 }) {
    if (!line) {
      return;
    }
    if (typeof x1 === "number") {
      line.setAttribute("x1", x1);
    }
    if (typeof x2 === "number") {
      line.setAttribute("x2", x2);
    }
    if (typeof y1 === "number") {
      line.setAttribute("y1", y1);
    }
    if (typeof x1 === "number") {
      line.setAttribute("y2", y2);
    }
    // 调用方式如下
    // setLineXYX1Y1({
    //     line: this.line,
    //     x1: this.line.getAttribute("x1"),
    //     y1: this.line.getAttribute("y1"),
    //     x2: centerX,
    //     y2: centerY,
    // });
  },
  // <path d="M25,25 Q100,150 175,275" style="stroke: red; fill: none" />
  /**
   * @description 曲线线
   */
  createCurve({ x1, y1, x2, y2, stroke = "red", strokeWidth = 1 }) {
    const p0 = "http://www.w3.org/2000/svg";
    const line = document.createElementNS(p0, "path");
    console.log("创建线x1", x1, "创建线x2", x2, "创建线y1", y1, "创建线y2", y2)
    line.setAttribute("d", `M ${x1} ${y1} ${x2} ${y2}`);
    line.setAttribute("stroke", stroke);
    line.setAttribute("stroke-width", strokeWidth);
    line.setAttribute("fill", "none");
    const g = document.getElementById("lineWrap");
    g.appendChild(line);
    // this.$emit("createline", line);
    return line;
  },
  /**
   * @description 曲线线
   */
  createRubber({ x1, y1, x2, y2, stroke = "red", strokeWidth = 1 }) {
    const p0 = "http://www.w3.org/2000/svg";
    const line = document.createElementNS(p0, "path");
    line.setAttribute("d", `M ${x1} ${y1} ${x2} ${y2}`);
    line.setAttribute("stroke", stroke);
    line.setAttribute("stroke-width", strokeWidth);
    line.setAttribute("fill", "none");
    const g = document.getElementById("lineWrap");
    g.appendChild(line);
    // this.$emit("createline", line);
    return line;
  },
  /**
   * @description 曲线终点
   */
  setCurveX1Y1({ line, x2, y2 }) {
    if (!line) {
      return;
    }
    if (typeof x2 === "number" && typeof y2 === "number") {
      let d = line.getAttribute("d");
      const arr = d.split(" ");
      arr[arr.length - 2] = x2; //`${x2},${y2}`
      arr[arr.length - 1] = y2; //`${x2},${y2}`
      line.setAttribute("d", arr.join(" "));
    }
  },
  /**
   * @description 曲线的弧度点
   */
  setCurveQXY({ line, x, y }) {
    if (!line) {
      return;
    }
    if (typeof x === "number" && typeof y === "number") {
      let d = line.getAttribute("d");
      const arr = d.split(" ");
      // 表明还没有设置弧度点
      if (arr.length === 5) {
        arr.splice(3, 0, `Q ${x} ${y}`);
        // 表示已经设置了弧度点
      } else if (arr.length === 8) {
        arr.splice(3, 3, `Q ${x} ${y}`);
      }

      console.log(arr.join(" "));
      line.setAttribute("d", arr.join(" "));
    }
  },
  /**
   * @description canvas转图片
   */
  convertCanvasToImage(canvas, image) {
    image.src = canvas.toDataURL("image/png");
    var url = canvas.toDataURL("image/png", 0.7); //得到图片的base64编码数据
    var a = document.createElement("a"); // 生成一个a元素
    var event = new MouseEvent("click"); // 创建一个单击事件
    a.download = "photo.png"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  },
  /**
   * @description 根据起始坐标点，求半径
   */
  getRByPointStartEnd(p0, p1) {
    const x = Math.abs(p0.x - p1.x);
    const y = Math.abs(p0.y - p1.y);
    return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  },
  /**
   * @description 颜色16进制转rgb
   */
  set16ToRgb(str) {
    let reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
    if (!reg.test(str)) {
      return;
    }
    let newStr = str.toLowerCase().replace(/\#/g, "");
    let len = newStr.length;
    if (len == 3) {
      let t = "";
      for (let i = 0; i < len; i++) {
        t += newStr.slice(i, i + 1).concat(newStr.slice(i, i + 1));
      }
      newStr = t;
    }
    let arr = []; //将字符串分隔，两个两个的分隔
    for (let i = 0; i < 6; i = i + 2) {
      let s = newStr.slice(i, i + 2);
      arr.push(parseInt("0x" + s));
    }
    // return 'rgb(' + arr.join(",") + ')';
    return arr.join(",");
  },
  isFive() {
    return location.hash === "#/drawingRules";
  },
  isSeven() {
    return location.hash === "#/drawingRulesSeven";
  },
  /**
   * 判断是往期还是未来四期
   * @param {Number} index
   * @returns Boolean
   */
  isFutureFour(index, qi = 30) {
    let flag = 0;
    if (obj.isFive()) {
      // flag = 180;
      flag = 6 * qi;
    } else if (obj.isSeven()) {
      // flag = 240;
      flag = 8 * qi;
    }
    if (index < flag) {
      return false;
    }
    return true;
  },
};

export default obj;
