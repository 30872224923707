import Step from "../Step";
import util from "../util";
const {
  hasSelectedBall,
  hideBall,
  showBall,
  createCurve,
  hideBallOpacity,
  showBallOpacity,
  setCurveX1Y1,
  getIndexBy,
  isFutureFour,
} = util;
const p0 = "http://www.w3.org/2000/svg";
export default {
  data() {
    return {
      shapeStyle: {},
      pageSize: sessionStorage.getItem("pageSize")
        ? Number(sessionStorage.getItem("pageSize"))
        : 30,
    };
  },
  methods: {
    // createRectDom({ x, y, w = 0, h = 0, stroke, strokeWidth, fill = "transparent" }) {
    //     this.startXY = [x, y];
    //     const line = document.createElementNS(p0, "rect");
    //     line.setAttribute("x", x);
    //     line.setAttribute("y", y);
    //     line.setAttribute("width", w);
    //     line.setAttribute("height", h);
    //     line.setAttribute("stroke", stroke);
    //     line.setAttribute("stroke-width", strokeWidth);
    //     line.setAttribute("fill", fill);
    //     const g = document.getElementById("lineWrap");
    //     g.appendChild(line);
    //     return line;
    // },
    createRect: function ({ centerX, centerY, index, isFilled = true }) {
      this.touchstartIndex = index;
      this.toggleDragImg(false);
      const target = this.divDomArr[index];
      // if (hasSelectedBall(target)) {
      //     hideBall(target);
      // } else {
      //     Step.add({ ball: index, backgroundColor: this.lineColor });
      //     showBall(target, {
      //         backgroundColor: this.lineColor,
      //     });
      // }
      if (hasSelectedBall(target)) {
        // hideBall(target);
        // if (isFutureFour(index)) {
        //     let rowIndex = Math.floor(this.numbserSelectorIndex / 6) - 30;
        //     const columnIndex = this.numbserSelectorIndex % 6;
        //     const lastArr = this.dataTwo[this.dataTwo.length - 1];
        //     rowIndex = lastArr.length - 4 + rowIndex;
        //     delete lastArr[rowIndex][`num${columnIndex}New`]
        //     this.dataTwo = [...this.dataTwo];
        // }
      } else {
        const lineColor = isFilled ? this.lineColor : "transparent";
        const color = isFilled ? "#ffffff" : this.lineColor;
        const borderColor = isFilled ? "#ffffff" : this.lineColor;
        this.shapeStyle = {
          borderRadius: 0,
          backgroundColor: lineColor,
          color,
          borderColor,
        };
        if (!isFutureFour(index, this.pageSize)) {
          Step.add({ ball: index, ...this.shapeStyle, target });
          if (isFutureFour(index, this.pageSize)) {
            showBall(target, { ...this.shapeStyle });
          }
        }
      }
      this.startXY = [centerX, centerY];
      this.line = createCurve({
        x1: centerX,
        y1: centerY,
        x2: centerX,
        y2: centerY,
        stroke: this.lineColor,
        strokeWidth: this.lineWidth,
      });
    },
    moveRect({ e, oLeft, oTop, divW, divH, ODragImgTop, isFilled = true }) {
      if (this.currentLineType1 === "intelligentLine") {
        this.toggleDragImg(true);
      }
      const source = this.divDomArr[this.touchstartIndex];
      // showBall(source, {
      //     backgroundColor: this.lineColor,
      // });
      showBall(source, { ...this.shapeStyle, borderRadius: 0 });
      // const lineColor = isFilled ? this.lineColor : "transparent"
      // const color = isFilled ? "#ffffff" : this.lineColor;
      // const borderColor = isFilled ? "#ffffff" : this.lineColor;
      // let style = {
      //     borderRadius: 0,
      //     backgroundColor: lineColor,
      //     color,
      //     borderColor,
      // }
      // showBall(source, { ...style });
      const { changedTouches } = e;
      const { pageX, pageY } = changedTouches[0];
      const [centerX, centerY, index] = getIndexBy(
        this.centerXYArr,
        pageX - oLeft,
        pageY - oTop,
        divW,
        divH
      );
      hideBallOpacity(this.moveBalls, index);
      this.moveBalls[index] = this.divDomArr[index];
      showBallOpacity(this.divDomArr[index], {
        backgroundColor: this.lineColor,
        ...this.shapeStyle,
        borderRadius: 0,
      });
      setCurveX1Y1({
        line: this.line,
        x2: centerX,
        y2: centerY,
      });
      this.setDragImgXY({
        x1: this.startXY[0],
        y1: this.startXY[1],
        x2: centerX,
        y2: centerY,
        ODragImgTop,
        oLeft,
      });
    },
    moveRectEnd({ centerX, centerY, index, isFilled = true }) {
      const target = this.divDomArr[index];
      // 判断当前的线首尾节点是否相当，如果相同就把线删除
      if (this.startXY[0] === centerX && this.startXY[1] === centerY) {
        this.$refs.svgRef.removeLine(this.line);
        this.toggleDragImg(false);
      } else {
        Step.add({ line: this.line });

        console.log(Step);
      }
      // 如果开始和结束都是同一个球，那么就表示是点击球，切换球的状态
      if (this.touchstartIndex === index) {
        if (!isFutureFour(index, this.pageSize)) {
          if (hasSelectedBall(target)) {
            hideBall(target);
          } else {
            // showBall(target);
            showBall(target, { ...this.shapeStyle, borderRadius: 0 });
          }
        } else {
          if (hasSelectedBall(target)) {
            let columnLength =
              location.hash.indexOf("drawingRulesSeven") >= 0 ? 8 : 6;
            let rowIndex = Math.floor(index / columnLength) - 30;
            const columnIndex = index % columnLength;
            const lastArr = this.dataTwo[this.dataTwo.length - 1];
            rowIndex = lastArr.length - 4 + rowIndex;
            delete lastArr[rowIndex][`num${columnIndex}New`];
            this.dataTwo = [...this.dataTwo];
            hideBall(target);
          } else {
            // const lineColor = isFilled ? this.lineColor : "transparent"
            // const color = isFilled ? "#ffffff" : this.lineColor;
            // const borderColor = isFilled ? "#ffffff" : this.lineColor;
            // this.shapeStyle = {
            //     borderRadius: 0,
            //     backgroundColor: lineColor,
            //     color,
            //     borderColor,
            // }
            const style = {
              ...this.shapeStyle,
              borderRadius: 0,
              backgroundColor: this.lineColor,
            };
            Step.add({ ball: index, ...style, target });
            this.numbserSelectorVisible = true;
            this.numbserSelectorIndex = index;
            showBall(target, style);
          }
        }
      } else {
        // const lineColor = isFilled ? this.lineColor : "transparent"
        // const color = isFilled ? "#ffffff" : this.lineColor;
        // const borderColor = isFilled ? "#ffffff" : this.lineColor;
        // let style = {
        //     borderRadius: 0,
        //     backgroundColor: lineColor,
        //     color,
        //     borderColor,
        // }
        if (hasSelectedBall(target)) {
          if (isFutureFour(index, this.pageSize)) {
            this.numbserSelectorVisible = true;
            this.numbserSelectorIndex = index;
          }
        }
        const style = {
          ...this.shapeStyle,
          borderRadius: 0,
          backgroundColor: this.lineColor,
        };
        Step.add({ ball: index, ...style, target });
        showBall(target, { ...this.shapeStyle, borderRadius: 0 });
      }
    },
  },
};
