<template>
    <section
        @touchstart="
            (e) => {
                e.stopPropagation();
                // e.preventDefault();
            }
        "
        @touchmove="
            (e) => {
                e.stopPropagation();
                e.preventDefault();
            }
        "
        @touchend="
            (e) => {
                e.stopPropagation();
                // e.preventDefault();
            }
        "
    >
        <div class="dr-sd-btn" @click="toggle">
            <img :src="currentImg" />
        </div>
        <ul class="dr-sd-list" v-show="selectToggle">
            <li
                @click="updateDrawType('intelligentLine', SelectBtn)"
                :class="{ active: lineActive === 0 }"
            >
                <p>智能线</p>
                <div>
                    <img :src="SelectBtn" />
                </div>
            </li>
            <li
                @click="updateDrawType('straightLine', Line)"
                :class="{ active: lineActive === 1 }"
            >
                <p>直线</p>
                <div>
                    <img :src="Line" />
                </div>
            </li>
            <li @click="updateDrawType('freedomLine', ZiYouXian)">
                <p>自由线</p>
                <div>
                    <img :src="ZiYouXian" />
                </div>
            </li>
            <!-- <li @click="updateDrawType('addText', WenZi)">
                <p>添加文字</p>
                <div>
                    <img :src="WenZi" />
                </div>
            </li> -->
            <li @click="updateDrawType('rubber', XiangPi)">
                <p>橡皮擦</p>
                <div>
                    <img :src="XiangPi" />
                </div>
            </li>
            <li @click="updateDrawType('filledCircle', ShiXinYuan)">
                <p>实心圆形</p>
                <div>
                    <img :src="ShiXinYuan" />
                </div>
            </li>
            <li @click="updateDrawType('filledRectangle', ShiXinFang)">
                <p>实心矩形</p>
                <div>
                    <img :src="ShiXinFang" />
                </div>
            </li>
            <li @click="updateDrawType('HollowCircle', KongXinYuan)">
                <p>空心圆形</p>
                <div>
                    <img :src="KongXinYuan" />
                </div>
            </li>
            <li @click="updateDrawType('HollowRectangle', KongXinFang)">
                <p>空心矩形</p>
                <div>
                    <img :src="KongXinFang" />
                </div>
            </li>
        </ul>
    </section>
</template>

<script>
import SelectBtn from "./images/s.png";
import Line from "./images/line.png";
import ZiYouXian from "./images/zexian.png";
import WenZi from "./images/text.png";
import XiangPi from "./images/xiangpi.png";
import ShiXinYuan from "./images/shixinyuan.png";
import ShiXinFang from "./images/shixinfang.png";
import KongXinYuan from "./images/kongxinyuan.png";
import KongXinFang from "./images/kongxinfang.png";
window.currentImg = ShiXinYuan;
export default {
    data() {
        return {
            SelectBtn,
            Line,
            ZiYouXian,
            WenZi,
            XiangPi,
            ShiXinYuan,
            ShiXinFang,
            KongXinYuan,
            KongXinFang,

            currentImg: ShiXinYuan,
            selectToggle: false,

            lineActive: 0,
        };
    },
    methods: {
        toggle() {
            this.selectToggle = !this.selectToggle;
        },
        updateDrawType(type, _currentImg) {
            this.selectToggle = false;
            // this.currentImg = _currentImg;
            // 智能线
            if (type === "intelligentLine") {
                console.log("智能线");
                this.lineActive = 0;
                this.currentImg = window.currentImg;
                this.$emit("updateDrawType1", type);
                // 直线
            } else if (type === "straightLine") {
                console.log("直线");
                this.lineActive = 1;
                this.currentImg = window.currentImg;
                this.$emit("updateDrawType1", type);
            } else if (type === "freedomLine") {
                console.log("自由线");
                this.$emit("updateDrawType", type);
                this.currentImg = _currentImg;
            } else {
                this.$emit("updateDrawType", type);
                window.currentImg = _currentImg;
                this.currentImg = _currentImg;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.dr-sd-btn {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 110;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    img {
        padding: 10px;
        width: 30px;
        height: 30px;
    }
}
.dr-sd-list {
    position: fixed;
    bottom: 70px;
    right: 10px;
    z-index: 110;
    li {
        &.active {
            p,
            div {
                background-color: #59a0dd;
            }
        }
        display: flex;
        align-items: center;
        height: 60px;
        p {
            background-color: rgba(0, 0, 0, 0.6);
            font-size: 18px;
            padding: 10px;
            border-radius: 6px;
            color: #fff;
            margin-right: 10px;
            width: 90px;
            text-align: center;
        }
        div {
            padding: 10px;
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img {
            width: 30px;
            height: 30px;
        }
    }
}
</style>