
import Step from "../Step";
import util from "../util"
const p0 = "http://www.w3.org/2000/svg";
const {
    showBall,
    hideBall,
    hasSelectedBall,
} = util;
export default {
    methods: {
        createRubberDom({ x1, y1, stroke, strokeWidth }) {
            const line = document.createElementNS(p0, "polyline");
            line.setAttribute("points", `${x1},${y1}`);

            line.setAttribute("style", `fill: none;stroke:${stroke};stroke-width:${strokeWidth}`);
            const g = document.getElementById("lineWrap");
            g.appendChild(line);
            return line;
        },
        createRubber: function ({ x, y, index }) {
            this.startXY = [x, y];
            this.line = this.createRubberDom({
                x1: x,
                y1: y,
                // stroke: this.lineColor,
                stroke: "#333",
                strokeWidth: this.lineWidth,
            });
            this.touchstartIndex = index;
        },
        moveRubberLine({ x, y }) {
            const points = this.line.getAttribute("points");
            const newPoints = `${points} ${x},${y}`;
            this.line.setAttribute("points", newPoints);
            
            console.log(Step)

        },
        moveRubbedomEnd({ centerX, centerY, index }) {
            Step.add({ line: this.line });
            if (this.touchstartIndex === index) {
                const target = this.divDomArr[index];
                if (hasSelectedBall(target)) {
                    hideBall(target);
                } else {
                    Step.add({ ball: index, backgroundColor: this.lineColor, target });
                    
                    console.log(this.lineColor);
                    showBall(target, {
                        backgroundColor: this.lineColor,
                    });
                }
            }
        }
    }
}