export default {
    /**
     * @description 智能线
     */
    INTELLIGENT_LINE: "intelligentLine",
    /**
     * @description 直线
     */
    STRAIGHT_LINE: "straightLine",
    /**
     * @description 自由线
     */
    FREEDOM_LINE: "freedomLine",
    /**
     * @description 文本
     */
    ADD_TEXT: "addText",
    /**
     * @description 橡皮
     */
    RUBBER: "rubber",
    /**
     * @description 实心圆
     */
    FILL_CIRCLE: "filledCircle",
    /**
     * @description 实心矩形
     */
    FILL_REACTANGLE: "filledRectangle",
    /**
     * @description 空心圆
     */
    HOLLOW_CIRCLE: "HollowCircle",
    /**
     * @description 空心矩形
     */
    HOLLOW_REACTANGLE: "HollowRectangle",
}