
import util from "../util"
const {
    showBall,
    hideBall,
    hasSelectedBall,
    createCurve,
    getIndexBy,
    hideBallOpacity,
    showBallOpacity,
    setCurveX1Y1,
    getRem
} = util;
import Step from "../Step";

export default {
    methods: {
        createCurve: function ({ centerX, centerY, index }) {
            this.toggleDragImg(false);
            this.touchstartIndex = index;
            const target = this.divDomArr[index];
            //     hideBall,
            //     hasSelectedBall,
            //     createCurve);
            if (hasSelectedBall(target)) {
                hideBall(target);
            } else {
                Step.add({ ball: index, backgroundColor: this.lineColor, target });
                // 去掉ball
                // showBall(target, {
                //     backgroundColor: this.lineColor,
                // });
            }
            this.startXY = [centerX, centerY];
            this.line = createCurve({
                x1: centerX,
                y1: centerY,
                x2: centerX,
                y2: centerY,
                stroke: this.lineColor,
                strokeWidth: this.lineWidth,
            });
        },
        moveCurve({ e, oLeft, oTop, divW, divH, ODragImgTop }) {
            this.toggleDragImg(true);
            const source = this.divDomArr[this.touchstartIndex];
            // 去掉ball
            // showBall(source, {
            //     backgroundColor: this.lineColor,
            // });
            const { changedTouches } = e;
            const { pageX, pageY } = changedTouches[0];
            const [centerX, centerY, index] = getIndexBy(
                this.centerXYArr,
                pageX - oLeft,
                pageY - oTop,
                divW,
                divH
            );
            hideBallOpacity(this.moveBalls, index);
            this.moveBalls[index] = this.divDomArr[index];
            // 去掉ball
            // showBallOpacity(this.divDomArr[index], {
            //     backgroundColor: this.lineColor,
            // });
            setCurveX1Y1({
                line: this.line,
                // x2: centerX,
                x2: pageX - oLeft,
                y2: pageY - oTop,
                // y2: centerY,
            });
            this.setDragImgXY({
                x1: this.startXY[0],
                y1: this.startXY[1],
                x2: centerX,
                y2: centerY,
                ODragImgTop,
                oLeft
            });
        },
        setDragImgXY({ x1, y1, x2, y2, ODragImgTop, oLeft }) {
            let xr = Math.abs(x1 - x2) / 2;
            let yr = Math.abs(y1 - y2) / 2;
            let imgWR = this.drgImgDom.clientWidth / 2;
            let imgHR = this.drgImgDom.clientHeight / 2;
            let x = 0;
            let y = 0;
            if (x2 < x1) {
                x = x1 - xr;
            } else {
                x = x1 + xr;
            }
            if (y2 < y1) {
                y = y1 - yr;
            } else {
                y = y1 + yr;
            }
            // this.drgImgDom.style.top = getRem(oTop + y - imgHR);
            this.drgImgDom.style.top = getRem(ODragImgTop + y - imgHR);
            this.drgImgDom.style.left = getRem(oLeft + x - imgWR);
        },
        moveCurveEnd({ centerX, centerY, index }) {
            // 判断当前的线首尾节点是否相当，如果相同就把线删除
            if (this.startXY[0] === centerX && this.startXY[1] === centerY) {
                this.$refs.svgRef.removeLine(this.line);
                this.toggleDragImg(false);
            } else {
                Step.add({ line: this.line });
            }
            // 如果开始和结束都是同一个球，那么就表示是点击球，切换球的状态
            const target = this.divDomArr[index];
            if (this.touchstartIndex === index) {
                // if (hasSelectedBall(target)) {
                //     hideBall(target);
                // } else {
                //     showBall(target);
                // }
            } else {
                Step.add({ ball: index, backgroundColor: this.lineColor, target });
                // 去掉ball
                // showBall(target, {
                //     backgroundColor: this.lineColor,
                // });
            }
        }
    }
}