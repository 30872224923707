import Step from "../Step";
import util from "../util";

const p0 = "http://www.w3.org/2000/svg";
const { showBall, hideBall, hasSelectedBall, isFutureFour } = util;
export default {
  methods: {
    createFreedomLineDom({ x1, y1, stroke, strokeWidth }) {
      const line = document.createElementNS(p0, "polyline");
      line.setAttribute("points", `${x1},${y1}`);

      line.setAttribute(
        "style",
        `fill: none;stroke:${stroke};stroke-width:${strokeWidth}`
      );
      const g = document.getElementById("lineWrap");
      g.appendChild(line);
      return line;
    },
    createFreedomLine: function ({ x, y, index }) {
      this.startXY = [x, y];
      this.line = this.createFreedomLineDom({
        x1: x,
        y1: y,
        stroke: this.lineColor,
        strokeWidth: this.lineWidth,
      });
      this.touchstartIndex = index;
    },
    moveFreedomLine({ x, y }) {
      const points = this.line.getAttribute("points");
      this.line.setAttribute("points", `${points} ${x},${y}`);
    },
    moveFreedomEnd({ centerX, centerY, index }) {
      // 如果开始和结束都是同一个球，那么就表示是点击球，切换球的状态
      const target = this.divDomArr[index];
      if (this.touchstartIndex === index) {
        if (
          isFutureFour(
            index,
            sessionStorage.getItem("pageSize")
              ? Number(sessionStorage.getItem("pageSize"))
              : 30
          )
        ) {
          if (hasSelectedBall(target)) {
            let columnLength =
              location.hash.indexOf("drawingRulesSeven") >= 0 ? 8 : 6;
            let rowIndex = Math.floor(index / columnLength) - 30;
            const columnIndex = index % columnLength;
            const lastArr = this.dataTwo[this.dataTwo.length - 1];
            rowIndex = lastArr.length - 4 + rowIndex;
            delete lastArr[rowIndex][`num${columnIndex}New`];
            this.dataTwo = [...this.dataTwo];
            hideBall(target);
          } else {
            // const lineColor = isFilled ? this.lineColor : "transparent"
            // const color = isFilled ? "#ffffff" : this.lineColor;
            // const borderColor = isFilled ? "#ffffff" : this.lineColor;
            // this.shapeStyle = {
            //     borderRadius: 0,
            //     backgroundColor: lineColor,
            //     color,
            //     borderColor,
            // }
            const style = {
              ...this.shapeStyle,
              borderRadius: 0,
              backgroundColor: this.lineColor,
            };
            Step.add({ ball: index, ...style, target });
            this.numbserSelectorVisible = true;
            this.numbserSelectorIndex = index;
            showBall(target, style);
          }
        }
      }
    },
  },
};
