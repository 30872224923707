var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"dr-color-selector",on:{"touchstart":(e) => {
            e.stopPropagation();
        },"touchmove":(e) => {
            e.stopPropagation();
        },"touchend":(e) => {
            e.stopPropagation();
        }}},[_c('div',{staticClass:"dr-cs-content"},[_c('div',{staticClass:"dr-display",style:({ 'background-color': _vm.currentColor }),on:{"click":_vm.toggleClick}},[_c('img',{staticClass:"dr-display-img",attrs:{"src":_vm.currentImg}})]),_c('ul',{staticClass:"dr-color-wrapper",style:({ height: _vm.toggle ? '400px' : 0 })},_vm._l((_vm.COLORS),function(color,i){return _c('li',{key:i,style:({ 'background-color': color }),on:{"click":function($event){return _vm.colorHandler(i)}}})}),0),_c('div',{staticClass:"line-width-selector-wrap",style:({ width: _vm.toggle ? '300px' : '50px' })},[_c('div',{staticClass:"line-width-selector",style:({ left: _vm.toggle ? '60px' : '-120px' })},_vm._l((_vm.lineArr),function(item,index){return _c('div',{key:index,staticClass:"dr-line-item",on:{"click":function($event){return _vm.lineWidthHandler(index)}}},[_c('img',{staticClass:"dr-display-img",attrs:{"src":item}})])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }