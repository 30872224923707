import Step from "../Step";
import util from "../util";
const {
  hasSelectedBall,
  hideBall,
  showBall,
  createCurve,
  hideBallOpacity,
  showBallOpacity,
  setCurveX1Y1,
  getIndexBy,
  isFutureFour,
  hideBallSeven
} = util;
const p0 = "http://www.w3.org/2000/svg";
export default {
  data() {
    return {
      shapeStyle: {},
    };
  },
  methods: {
    // createCircleDom({ cx, cy, stroke, r = 0, strokeWidth, fill = "transparent" }) {
    //     this.startXY = [cx, cy];
    //     const line = document.createElementNS(p0, "circle");
    //     line.setAttribute("cx", cx);
    //     line.setAttribute("cy", cy);
    //     line.setAttribute("r", r);
    //     line.setAttribute("stroke", stroke);
    //     line.setAttribute("stroke-width", strokeWidth);
    //     line.setAttribute("fill", fill);
    //     const g = document.getElementById("lineWrap");
    //     g.appendChild(line);
    //     return line;
    // },
    createCircle: function ({ centerX, centerY, index, isFilled = true }) {
      this.touchstartIndex = index;
      this.toggleDragImg(false);
      const target = this.divDomArr[index];
      // if (hasSelectedBall(target)) {
      //     hideBall(target);
      // } else {
      //     Step.add({ ball: index, backgroundColor: this.lineColor });
      //     showBall(target, {
      //         backgroundColor: this.lineColor,
      //     });
      // }
      // if (hasSelectedBall(target)) {

      //     hideBall(target);
      // } else {
      const lineColor = isFilled ? this.lineColor : "transparent";
      const color = isFilled ? "#ffffff" : this.lineColor;
      const borderColor = isFilled ? "#ffffff" : this.lineColor;
      this.shapeStyle = {
        borderRadius: isFutureFour(
          index,
          sessionStorage.getItem("pageSize")
            ? Number(sessionStorage.getItem("pageSize"))
            : 30
        )
          ? 0
          : "50%",
        backgroundColor: lineColor,
        color,
        borderColor,
      };
      Step.add({ ball: index, ...this.shapeStyle, target });
      // showBall(target, { ...style });
      // }
      this.startXY = [centerX, centerY];
      this.line = createCurve({
        x1: centerX,
        y1: centerY,
        x2: centerX,
        y2: centerY,
        stroke: this.lineColor,
        strokeWidth: this.lineWidth,
      });
      // this.line = this.createCircleDom({
      //     cx: x,
      //     cy: y,
      //     r,
      //     stroke: this.lineColor,
      //     strokeWidth: this.lineWidth,
      //     fill
      // });
    },
    getBorderRadius(index) {
      return isFutureFour(
        index,
        sessionStorage.getItem("pageSize")
          ? Number(sessionStorage.getItem("pageSize"))
          : 30
      )
        ? 0
        : "50%";
    },
    moveCircle({ e, oLeft, oTop, divW, divH, ODragImgTop, isFilled = true }) {
      if (this.currentLineType1 === "intelligentLine") {
        this.toggleDragImg(true);
      }
      const source = this.divDomArr[this.touchstartIndex];
      // showBall(source, {
      //     backgroundColor: this.lineColor,
      // });
      showBall(source, {
        ...this.shapeStyle,
        borderRadius: this.getBorderRadius(this.touchstartIndex),
      });
      // const lineColor = isFilled ? this.lineColor : "transparent"
      // const color = isFilled ? "#ffffff" : this.lineColor;
      // const borderColor = isFilled ? "#ffffff" : this.lineColor;
      // let style = {
      //     borderRadius: "50%",
      //     backgroundColor: lineColor,
      //     color,
      //     borderColor,
      // }
      // showBall(source, { ...style });
      const { changedTouches } = e;
      const { pageX, pageY } = changedTouches[0];
      const [centerX, centerY, index] = getIndexBy(
        this.centerXYArr,
        pageX - oLeft,
        pageY - oTop,
        divW,
        divH
      );
      hideBallOpacity(this.moveBalls, index);
      this.moveBalls[index] = this.divDomArr[index];
      showBallOpacity(this.divDomArr[index], {
        backgroundColor: this.lineColor,
        ...this.shapeStyle,
        borderRadius: this.getBorderRadius(index),
      });
      setCurveX1Y1({
        line: this.line,
        x2: centerX,
        y2: centerY,
      });
      this.setDragImgXY({
        x1: this.startXY[0],
        y1: this.startXY[1],
        x2: centerX,
        y2: centerY,
        ODragImgTop,
        oLeft,
      });
      // const x = this.startXY[0];
      // const y = this.startXY[0];
      // let cx = 0;
      // let cy = 0;
      // let x_x1_2 = Math.abs(x - x1) / 2;
      // let y_y1_2 = Math.abs(y - y1) / 2;
      // if (x1 > x) {
      //     cx = x + x_x1_2
      // } else if (x === x1) {
      //     cx = x
      // } else {
      //     cx = x - x_x1_2
      // }
      // if (y1 > y) {
      //     cy = y + y_y1_2
      // } else if (y === y1) {
      //     cy = y
      // } else {
      //     cy = y - y_y1_2
      // }
      // this.line.setAttribute("cx", cx);
      // this.line.setAttribute("cy", cy);
      // this.line.setAttribute("r", r);
    },
    moveCircleEnd({ centerX, centerY, index, isFilled = true }) {
      const target = this.divDomArr[index];
      // 判断当前的线首尾节点是否相当，如果相同就把线删除
      if (this.startXY[0] === centerX && this.startXY[1] === centerY) {
        this.$refs.svgRef.removeLine(this.line);
        this.toggleDragImg(false);
      } else {
        Step.add({ line: this.line });
      }
      // 如果开始和结束都是同一个球，那么就表示是点击球，切换球的状态
      if (this.touchstartIndex === index) {
        if (hasSelectedBall(target)) {
          hideBallSeven(target);
        } else {
          // showBall(target);

          // showBall(target, { ...this.shapeStyle, borderRadius: this.getBorderRadius(this.touchstartIndex) });
          showBall(target, {
            ...this.shapeStyle,
            borderRadius: this.getBorderRadius(index),
          });
        }
      } else {
        // const lineColor = isFilled ? this.lineColor : "transparent"
        // const color = isFilled ? "#ffffff" : this.lineColor;
        // const borderColor = isFilled ? "#ffffff" : this.lineColor;
        // let style = {
        //     borderRadius: "50%",
        //     backgroundColor: lineColor,
        //     color,
        //     borderColor,
        // }
        Step.add({ ball: index, ...this.shapeStyle, target });
        // showBall(target, { ...this.shapeStyle, borderRadius: this.getBorderRadius(this.touchstartIndex) });
        showBall(target, {
          ...this.shapeStyle,
          borderRadius: this.getBorderRadius(index),
        });
        // if (isFutureFour(index)) {
        //     showBall(target, { ...this.shapeStyle });
        // } else {
        //     showBall(target, { ...this.shapeStyle, borderRadius: 0 });
        // }
      }
    },
  },
};
