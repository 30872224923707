/**
 * @description 步骤记录器
 */
function Step() {
    this.containter = [];
    this.currentIndex = -1;
}

/**
 * @description 添加步骤
 * @param {*} item 
 */
Step.prototype.add = function (item) {
    // 如果当前索引是在最后一个位置上，那么就直接添加
    if (this.currentIndex === this.containter.length - 1) {
        if (item && typeof item.ball === "number") {
            console.log(this.containter.filter(item => !!item.ball).map(item => item.ball));
            
            if (!this.containter.filter(item1 => typeof item1.ball === "number").map(item2 => item2.ball).includes(item.ball)) {
                this.containter.push(item);
                this.currentIndex++;
            }
        } else {
            this.containter.push(item);
            this.currentIndex++;
        }
        // 如果当前索引不在最后一位
    } else if (this.currentIndex < this.containter.length - 1) {
        this.containter.splice(this.currentIndex + 1, this.containter.length - this.currentIndex + 1, item);
        this.currentIndex = this.containter.length - 1;
    }
    console.log(this.containter);
    
}

/**
 * @description 撤销
 * @returns 起始项到currentIndex项
 */
Step.prototype.revoke = function () {
    if (this.currentIndex > -1) {
        const item = this.containter.slice(this.currentIndex, this.currentIndex + 1);
        if (item && item.length === 1) {
            this.currentIndex--;
            return item[0]
        }
    }
    return null;
}
/**
 * @description 恢复
 * @return 
 */
Step.prototype.recovery = function () {
    if (this.currentIndex < this.containter.length - 1) {
        this.currentIndex++;
        return this.containter.slice(this.currentIndex, this.currentIndex + 1)[0];
    }
    return null;
}

/**
 * @description 清楚
 */
Step.prototype.clear = function () {
    this.containter = [];
    this.currentIndex = -1;
}

export default new Step();