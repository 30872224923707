<template>
    <div>
        <van-popup
            :value="show"
            position="bottom"
            @click-overlay="close"
            :style="{ height: '80%' }"
        >
            <h3>数字选择器</h3>
            <dl>
                <dt>选择号码</dt>
                <dd>
                    <div
                        class="ns-ball"
                        v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
                        :key="i"
                        :class="{ active: num6.includes(i) }"
                        @touchend="selectNumber6(i)"
                    >
                        {{ i }}
                    </div>
                    <!-- <div class="ns-ball">1</div>
                    <div class="ns-ball">2</div>
                    <div class="ns-ball">3</div>
                    <div class="ns-ball">4</div>
                    <div class="ns-ball">5</div>
                    <div class="ns-ball">6</div>
                    <div class="ns-ball">7</div>
                    <div class="ns-ball">8</div>
                    <div class="ns-ball">9</div> -->
                </dd>
            </dl>
            <dl>
                <dt>选择对数</dt>
                <dd>
                    <div
                        class="ns-ball"
                        :class="{ active: log.includes('0/5') }"
                        @touchend="selectLog"
                    >
                        0/5
                    </div>
                    <div
                        class="ns-ball"
                        :class="{ active: log.includes('1/6') }"
                        @touchend="selectLog"
                    >
                        1/6
                    </div>
                    <div
                        class="ns-ball"
                        :class="{ active: log.includes('2/7') }"
                        @touchend="selectLog"
                    >
                        2/7
                    </div>
                    <div
                        class="ns-ball"
                        :class="{ active: log.includes('3/8') }"
                        @touchend="selectLog"
                    >
                        3/8
                    </div>
                    <div
                        class="ns-ball"
                        :class="{ active: log.includes('4/9') }"
                        @touchend="selectLog"
                    >
                        4/9
                    </div>
                </dd>
            </dl>
            <dl>
                <dt>单双大小</dt>
                <dd>
                    <div
                        class="ns-ball"
                        :class="{ active: dsdx.includes('单') }"
                        @touchend="selectDSDX"
                    >
                        单
                    </div>
                    <div
                        class="ns-ball"
                        :class="{ active: dsdx.includes('双') }"
                        @touchend="selectDSDX"
                    >
                        双
                    </div>
                    <div
                        class="ns-ball"
                        :class="{ active: dsdx.includes('大') }"
                        @touchend="selectDSDX"
                    >
                        大
                    </div>
                    <div
                        class="ns-ball"
                        :class="{ active: dsdx.includes('小') }"
                        @touchend="selectDSDX"
                    >
                        小
                    </div>
                    <div
                        class="ns-ball"
                        :class="{ active: dsdx.includes('X') }"
                        @touchend="selectDSDX"
                    >
                        X
                    </div>
                </dd>
            </dl>
            <van-button
                @click="onSubmit"
                type="primary"
                size="large"
                class="submit-btn"
                >确 定</van-button
            >
        </van-popup>
    </div>
</template>

<script>
import { Notify } from "vant";
import Step from "./Step";

let valueCache = {};
export default {
    props: {
        index: {
            default: -1,
            type: Number,
        },
        show: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            num6: [],
            log: [],
            dsdx: [],
        };
    },
    watch: {
        show(isShow) {
            this.num6 = [];
            this.log = [];
            this.dsdx = [];
            if (isShow && valueCache[this.index]) {
                const { key, value } = valueCache[this.index];
                if (key === "num6") {
                    this[key] = value.split("").map((item) => Number(item));
                } else if (key === "log") {
                    this[key] = [value];
                } else if (key === "dsdx") {
                    this[key] = [value];
                }
            }
        },
    },
    methods: {
        clear() {
            this.num6 = [];
            this.log = [];
            this.dsdx = [];
            valueCache = {};
        },
        close() {
            this.$emit("update:show", false);
        },
        selectNumber6(num) {
            if (this.num6.includes(num)) {
                this.num6.splice(this.num6.indexOf(num), 1);
            } else {
                if (this.num6.length < 6) {
                    this.log = [];
                    this.dsdx = [];
                    this.num6.push(num);
                } else {
                    Notify({
                        type: "warning",
                        message: "最多可以选择六个球",
                        duration: 1000,
                    });
                }
            }
        },
        selectLog(e) {
            this.num6 = [];
            this.dsdx = [];
            this.log = [e.target.innerText];
        },
        selectDSDX(e) {
            this.log = [];
            this.num6 = [];
            this.dsdx = [e.target.innerText];
        },
        // touchend(e) {
        //     this.$emit("select", e.target.innerText);
        // },
        onSubmit() {
            const stepRecord = Step.containter.find(
                (item) => item.ball === this.index
            );
            let value = null;
            if (this.num6.length > 0) {
                value = this.num6.sort().join("");
                valueCache[this.index] = { key: "num6", value };
                stepRecord.innerText = value;
                this.$emit("select", value);
            } else if (this.log.length > 0) {
                value = this.log.join("");
                valueCache[this.index] = { key: "log", value };
                stepRecord.innerText = value;
                this.$emit("select", value);
            } else if (this.dsdx.length > 0) {
                value = this.dsdx.join("");
                valueCache[this.index] = { key: "dsdx", value };
                stepRecord.innerText = value;
                this.$emit("select", value);
            } else {
                Notify({
                    type: "warning",
                    message: "请选择号码、对数、单双大小",
                    duration: 1000,
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
@width: 1.2rem;
@m: 0.3rem;
h3 {
    font-size: 21px;
}
dl {
    margin-top: 10px;
    margin-bottom: 10px;
}
dt {
    font-size: 18px;
    text-align: left;
    text-indent: 32px;
}
dd {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .ns-ball {
        font-size: 14px;
        border-radius: 50%;
        background-color: #f1f1f1;
        line-height: @width;
        width: @width;
        height: @width;
        margin: @m;
        &.active {
            color: #fff;
            background-color: orange;
        }
    }
}
.submit-btn {
    width: 90%;
    margin: 10px auto;
}
</style>