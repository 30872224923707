<template>
    <section
        class="dr-color-selector"
        @touchstart="
            (e) => {
                e.stopPropagation();
            }
        "
        @touchmove="
            (e) => {
                e.stopPropagation();
            }
        "
        @touchend="
            (e) => {
                e.stopPropagation();
            }
        "
    >
        <div class="dr-cs-content">
            <div
                class="dr-display"
                :style="{ 'background-color': currentColor }"
                @click="toggleClick"
            >
                <img :src="currentImg" class="dr-display-img" />
            </div>
            <ul
                class="dr-color-wrapper"
                :style="{ height: toggle ? '400px' : 0 }"
            >
                <li
                    v-for="(color, i) in COLORS"
                    :key="i"
                    :style="{ 'background-color': color }"
                    @click="colorHandler(i)"
                ></li>
            </ul>
            <!--  :style="{ width: toggle ? '165px' : 0 }" -->
            <div
                class="line-width-selector-wrap"
                :style="{ width: toggle ? '300px' : '50px' }"
            >
                <div
                    class="line-width-selector"
                    :style="{ left: toggle ? '60px' : '-120px' }"
                >
                    <div
                        v-for="(item, index) in lineArr"
                        :key="index"
                        class="dr-line-item"
                        @click="lineWidthHandler(index)"
                    >
                        <img :src="item" class="dr-display-img" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LINE1 from "./images/draw-line1.png";
import LINE2 from "./images/draw-line2.png";
import LINE3 from "./images/draw-line3.png";

// 蓝色、绿色、橙色、红色、灰色
// const COLORS = ["#409EFF", "#5CB87A", "#E6A23C", "#F56C6C", "#8896B3"];
const COLORS = [
    "#409EFF",
    "#fe0000",
    "#0000fe",
    "#6600ff",
    "#ff7f29",
    "#5eb95e",
    "#a45d34",
    "#19d6cf",
    "#00000a",
    "#f4cc03",
    "#bfff3f",
    "#a300a8",
    "#00ff01",
];
const LINE_WIDTH = [1, 2, 5];
export default {
    data() {
        return {
            LINE1,
            LINE2,
            LINE3,
            lineArr: [LINE1, LINE2, LINE3],
            COLORS,
            currentColor: COLORS[0],
            cColorIndex: 0,
            currentLineWidth: LINE_WIDTH[0],
            cLineWidthIndex: 1,

            toggle: false,
        };
    },
    computed: {
        currentImg() {
            return this.lineArr[this.cLineWidthIndex];
        },
    },
    methods: {
        toggleClick() {
            this.toggle = !this.toggle;
        },
        hideToggle() {
            this.toggle = false;
        },
        lineWidthHandler(index) {
            this.currentLineWidth = LINE_WIDTH[index];
            this.cLineWidthIndex = index;
            const lw = LINE_WIDTH[index];
            this.$emit("lineWidthHandler", lw);
            this.hideToggle();
        },
        colorHandler(index) {
            this.currentColor = COLORS[index];
            const c = COLORS[index];
            this.$emit("colorHandler", c);
            this.hideToggle();
        },
    },
};
</script>

<style lang="less" scoped>
.dr-color-selector {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 110;
}
.dr-cs-content {
    position: relative;
    width: 50px;
    height: 50px;
    // background-color: red;
}
.dr-display {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.dr-display-img {
    width: 60%;
    height: 60%;
    padding: 20%;
    border-radius: 50%;
}
.line-width-selector-wrap {
    transition: all 0.6s;
    position: absolute;
    left: 0;
    top: 0;
    height: 50px;
    line-height: 50px;
    width: 300px;
    overflow: hidden;
    z-index: -1;
    border-radius: 25px;
}
.line-width-selector {
    position: absolute;
    top: 0;
    // left: 60px;
    display: flex;
    overflow: hidden;
    transition: all 0.6s;
    z-index: 110;
}
.dr-line-item {
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    margin-right: 10px;
}
.dr-color-wrapper {
    overflow: auto;
    position: absolute;
    left: 0;
    bottom: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    // padding-top: 5px;
    // padding-bottom: 5px;
    // overflow: hidden;
    transition: all 0.2s;
    z-index: 110;
    li {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 5px;
    }
}
</style>